
function Content({data}) {

    const defaultImage = 'assets/placeholder-image.jpg'; // Path to default image

    const handleImageError = (e) => {
        e.target.src = defaultImage; // Set the default image source
    };

    return (
        <div className="content-container">
            <div className="pgNum">
                {data.id}
            </div>
            {/*<img src="/assets/1.jpeg" alt="Intro Screen" />*/}
            <img src={data.www} alt={data.text} onError={handleImageError} className="image-size"/>
            {data.type === 'vid' && (
                <div className={'vid-container ' + `${(data.style!=null) ? data.style : ''}`}>
                    <video className={`${(data.style!=null) ? data.style : ''}`} controls controlsList="nodownload" poster={data.poster} height="auto">
                        {/*<source src={data.vidSrc + '#t=0.001'} type="video/mp4" />*/}
                        <source src={data.vidSrc} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            )}
        </div>
    );

}

export default Content;