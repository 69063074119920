import React, { useEffect, useState } from 'react';
import "./App.css";

import Section from "./Section";

function App() {
  console.log('App');

  const [scale, setScale] = useState(1);
  const [currentSection, setCurrentSection] = useState(1);

  // const sectionArr = [
  //   {
  //     id:1,
  //     text: "Screen 1",
  //     www: "assets/1.jpg",
  //     type: "img"
  //   },
  //   {
  //     id:2,
  //     text: "Screen 2",
  //     www: "assets/2.jpg",
  //     vidSrc : "assets/L1-U27-P1-CP.mp4",
  //     poster : "assets/vidPosters/L1-U27-P1-CP.png",
  //     style : "vid-right",
  //     type: "vid"
  //   },
  //   {
  //     id:3,
  //     text: "Screen 3",
  //     www: "assets/3.jpg",
  //     vidSrc : "assets/L1-U27-P3-PG.mp4",
  //     poster : "assets/vidPosters/L1-U27-P3-PG.png",
  //     type: "vid"
  //   },
  //   {
  //     id:4,
  //     text: "Screen 4",
  //     www: "assets/4.jpg",
  //     type: "img"
  //   },
  //   {
  //     id:5,
  //     text: "Screen 5",
  //     www: "assets/5.jpg",
  //     type: "img"
  //   },
  //   {
  //     id:6,
  //     text: "Screen 6",
  //     www: "assets/6.jpg",
  //     vidSrc : "assets/L1-U27-P4-VR.mp4",
  //     poster : "assets/vidPosters/L1-U27-P4-VR.png",
  //     type: "vid"
  //   },
  //   {
  //     id:7,
  //     text: "Screen 7",
  //     www: "assets/7.jpg",
  //     type: "img"
  //   },
  //   {
  //     id:8,
  //     text: "Screen 8",
  //     www: "assets/8.jpg",
  //     type: "img"
  //   },
  //   {
  //     id:9,
  //     text: "Screen 9",
  //     www: "assets/9.jpg",
  //     vidSrc : "assets/L1-U27-P2-MV.mp4",
  //     poster : "assets/vidPosters/L1-U27-P2-MV.png",
  //     type: "vid"
  //   },
  //   {
  //     id:10,
  //     text: "Screen 10",
  //     www: "assets/10.jpg",
  //     vidSrc : "assets/L1-U27-P5-GT.mp4",
  //     poster : "assets/vidPosters/L1-U27-P5-GT.png",
  //     type: "vid"
  //   },
  //   {
  //     id:11,
  //     text: "Screen 11",
  //     www: "assets/11.jpg",
  //     type: "img"
  //   },
  //   {
  //     id:12,
  //     text: "Screen 12",
  //     www: "assets/12.jpg",
  //     type: "img"
  //   },
  //   {
  //     id:13,
  //     text: "Screen 13",
  //     www: "assets/13.jpg",
  //     vidSrc : "assets/L1-U27-P6-K.mp4",
  //     poster : "assets/vidPosters/L1-U27-P6-K.png",
  //     type: "vid"
  //   },
  //   {
  //     id:14,
  //     text: "Screen 14",
  //     www: "assets/14.jpg",
  //     type: "img"
  //   },
  //   {
  //     id:15,
  //     text: "Screen 15",
  //     www: "assets/15.jpg",
  //     type: "img"
  //   },
  //   {
  //     id:16,
  //     text: "Screen 16",
  //     www: "assets/16.jpg",
  //     type: "img"
  //   },
  //   {
  //     id:17,
  //     text: "Screen 17",
  //     www: "assets/17.jpg",
  //     vidSrc : "assets/Learning-To-Koala.mp4",
  //     poster : "assets/vidPosters/Learning-To-Koala.jpg",
  //     type: "vid"
  //   },
  //   {
  //     id:18,
  //     text: "Screen 18",
  //     www: "assets/18.jpg",
  //     type: "img"
  //   },
  //   {
  //     id:19,
  //     text: "Screen 19",
  //     www: "assets/19.jpg",
  //     type: "img"
  //   },
  //   {
  //     id:20,
  //     text: "Screen 20",
  //     www: "assets/20.jpg",
  //     type: "img"
  //   },
  //   {
  //     id:21,
  //     text: "Screen 21",
  //     www: "assets/21.jpg",
  //     vidSrc : "assets/L1-U27-P2-MV.mp4",
  //     poster : "assets/vidPosters/L1-U27-P2-MV.png",
  //     style : "vid-left",
  //     type: "vid"
  //   },
  //   {
  //     id:22,
  //     text: "Screen 22",
  //     www: "assets/22.jpg",
  //     type: "img"
  //   },
  //   {
  //     id:23,
  //     text: "Screen 23",
  //     www: "assets/23.jpg",
  //     type: "img"
  //   }
  // ];

  const sectionArr = [
    {
      id:1,
      text: "Screen 1",
      www: "assets/webp/1.webp",
      type: "img"
    },
    {
      id:2,
      text: "Screen 2",
      www: "assets/webp/2.webp",
      vidSrc : "assets/video/L1-U27-P1-CP.mp4",
      poster : "assets/vidPosters/L1-U27-P1-CP.webp",
      style : "vid-right",
      type: "vid"
    },
    {
      id:3,
      text: "Screen 3",
      www: "assets/webp/3.webp",
      vidSrc : "assets/video/L1-U27-P3-PG.mp4",
      poster : "assets/vidPosters/L1-U27-P3-PG.webp",
      type: "vid"
    },
    {
      id:4,
      text: "Screen 4",
      www: "assets/webp/4.webp",
      type: "img"
    },
    {
      id:5,
      text: "Screen 5",
      www: "assets/webp/5.webp",
      type: "img"
    },
    {
      id:6,
      text: "Screen 6",
      www: "assets/webp/6.webp",
      vidSrc : "assets/video/L1-U27-P4-VR.mp4",
      poster : "assets/vidPosters/L1-U27-P4-VR.webp",
      type: "vid"
    },
    {
      id:7,
      text: "Screen 7",
      www: "assets/webp/7.webp",
      type: "img"
    },
    {
      id:8,
      text: "Screen 8",
      www: "assets/webp/8.webp",
      type: "img"
    },
    {
      id:9,
      text: "Screen 9",
      www: "assets/webp/9.webp",
      vidSrc : "assets/video/L1-U27-P2-MV.mp4",
      poster : "assets/vidPosters/L1-U27-P2-MV.webp",
      type: "vid"
    },
    {
      id:10,
      text: "Screen 10",
      www: "assets/webp/10.webp",
      vidSrc : "assets/video/L1-U27-P5-GT.mp4",
      poster : "assets/vidPosters/L1-U27-P5-GT.webp",
      type: "vid"
    },
    {
      id:11,
      text: "Screen 11",
      www: "assets/webp/11.webp",
      type: "img"
    },
    {
      id:12,
      text: "Screen 12",
      www: "assets/webp/12.webp",
      type: "img"
    },
    {
      id:13,
      text: "Screen 13",
      www: "assets/webp/13.webp",
      vidSrc : "assets/video/L1-U27-P6-K.mp4",
      poster : "assets/vidPosters/L1-U27-P6-K.webp",
      type: "vid"
    },
    {
      id:14,
      text: "Screen 14",
      www: "assets/webp/14.webp",
      type: "img"
    },
    {
      id:15,
      text: "Screen 15",
      www: "assets/webp/15.webp",
      type: "img"
    },
    {
      id:16,
      text: "Screen 16",
      www: "assets/webp/16.webp",
      type: "img"
    },
    {
      id:17,
      text: "Screen 17",
      www: "assets/webp/17.webp",
      vidSrc : "assets/video/Learning-To-Koala.mp4",
      poster : "assets/vidPosters/Learning-To-Koala.webp",
      type: "vid"
    },
    {
      id:18,
      text: "Screen 18",
      www: "assets/webp/18.webp",
      type: "img"
    },
    {
      id:19,
      text: "Screen 19",
      www: "assets/webp/19.webp",
      type: "img"
    },
    {
      id:20,
      text: "Screen 20",
      www: "assets/webp/20.webp",
      type: "img"
    },
    {
      id:21,
      text: "Screen 21",
      www: "assets/webp/21.webp",
      vidSrc : "assets/video/L1-U27-P2-MV.mp4",
      poster : "assets/vidPosters/L1-U27-P2-MV.webp",
      style : "vid-left",
      type: "vid"
    },
    {
      id:22,
      text: "Screen 22",
      www: "assets/webp/22.webp",
      type: "img"
    },
    {
      id:23,
      text: "Screen 23",
      www: "assets/webp/23.webp",
      type: "img"
    }
  ];




  useEffect(() => {

    const pgValue = getURLQuery('pg');
    //console.log('pgValue:', pgValue);
    let pgNum = Number(pgValue);
    if (pgNum && isValidNumber(pgNum) === true){
      console.log('pgNum:', pgNum);
      setCurrentSection(pgNum);
    }

    const handleResize = () => {
      const { innerWidth: width, innerHeight: height } = window;
      // Assuming the original dimensions of the image
      const originalWidth = 1600; // Replace with actual width
      const originalHeight = 914; // Replace with actual height

      const widthScale = width / originalWidth;
      const heightScale = height / originalHeight;
      const newScale = Math.min(widthScale, heightScale);

      setScale(newScale);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call it initially

    return () => window.removeEventListener('resize', handleResize);

  }, []);

  function getURLQuery(paramName) {
    try {
      const url = window.location.href; // Gets the current URL
      const parsedURL = new URL(url);
      const queryParams = new URLSearchParams(parsedURL.search);
      return queryParams.get(paramName); // Gets the value of the specified query parameter
      //console.log(queryParams.get(paramName));
      // if(queryParams.get(paramName) != null && queryParams.get(paramName) != ""){
      //   setCurrentSection(queryParams.get(paramName));
      // }
    } catch (error) {
      console.error("Error parsing URL", error);
      return null;
    }
  };

  function isValidNumber(value) {
    const regex = /^(?:[1-9]|1[0-9]|2[0-3])$/;
    return regex.test(value);
  }

  function btnBackClicked() {
    console.log('App:btnBackClicked:');
    stopAllVideos();
    if(currentSection > 1){
      setCurrentSection(current => current - 1);
    }
  }

  function btnNextClicked() {
    console.log('App:btnNextClicked:');
    stopAllVideos();
    if(currentSection < sectionArr.length){
      setCurrentSection(current => current + 1);
    }
  }

  function stopAllVideos() {
    let videos = document.querySelectorAll('video');
    videos.forEach(function(video) {
      video.pause();
    });
  }

  return (
      <div className="main-container">
        <div className="scale-container" style={{ transform: `scale(${scale})` }}>
          {sectionArr.map((data) => (
              <Section key={data.id} data={data} currentSection={currentSection}/>
          ))}
          <footer className="footer">
            <button onClick={btnBackClicked} disabled={currentSection === 1}>Button Back</button>
            <button onClick={btnNextClicked} disabled={currentSection === sectionArr.length}>Button Next</button>
          </footer>
        </div>

      </div>
  );
}

export default App;
