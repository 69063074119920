import Content from "./Content";

function Section({data, currentSection}) {
    // console.log('Section');

    return (
        // <section className="image-container">
        <section className={`${(currentSection===data.id) ? 'section-container' : 'section-container hidden'}`}>
            <Content data={data}/>
        </section>
    );

}


export default Section;